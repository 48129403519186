import { Injectable } from '@angular/core';
import { IWithdraw, WITHDRAW_CANCEL_REASON, WITHDRAW_STATE } from '@libs/interfaces';
import { MapResponseData } from '@libs/rxjs/operators.rxjs';
import { Observable } from 'rxjs';
import { ApiService } from '../../api.service';

@Injectable({
  providedIn: 'root',
})
export class WithdrawsService {
  constructor(public api: ApiService) {}
  userApplied(userId: string): Observable<IWithdraw[]> {
    return this.api.get(`withdraws/user/applied/${userId}`).pipe(MapResponseData);
  }
  fetchPendingWithdraws(state: WITHDRAW_STATE = WITHDRAW_STATE.PENDING): Observable<IWithdraw[]> {
    return this.api.get(`withdraws/fetchWithdrawal/${state}`).pipe(MapResponseData);
  }
  applyWithdraw(wd: IWithdraw): Observable<any> {
    return this.api.post(`withdraws/applyWithdraw`, wd).pipe(MapResponseData);
  }
  applyWithdraws(wd: IWithdraw[]): Observable<any> {
    const withdraws = wd.filter((w: any) => !w.forbidden);
    return this.api.post(`withdraws/applyWithdraws`, { withdraws }).pipe(MapResponseData);
  }
  cancelWithdraw(wd: IWithdraw, reason: WITHDRAW_CANCEL_REASON): Observable<any> {
    return this.api.get(`withdraws/cancelWithdraw/${wd._id}/${reason}`);
  }
}
